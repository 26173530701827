import React, { Fragment } from 'react';
import { shape, oneOfType, func, instanceOf } from 'prop-types';
import qs from 'qs';
import { useQuery } from '@apollo/react-hooks';
import { useStoreActions } from 'easy-peasy';
import styled from 'styled-components/macro';
import MediaQuery from 'react-responsive';
import { withStyles } from '@material-ui/core/styles';
import idx from 'idx';

import ContentWrapper from '../../components/ContentWrapper';
import Filters, { filterKeys } from '../../components/Filters';
import MobileFilters from '../../components/MobileFilters';
import GET_PRODUCTS from './product-query';
import Table from '../../components/Table';
import Loading from '../../components/Loading';

const FilterContainer = styled.div`
  background: #f0f0f0;
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: center;
`;

const FilterTitle = styled.h2`
  font-size: 24px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const getVariables = where => {
  if (Object.keys(where).length === 0 && where.constructor === Object) {
    return {};
  }
  const indexes = Object.keys(where).map(key => filterKeys.indexOf(key));
  const filterLevel = Math.max(...indexes);
  if (filterLevel < 0) {
    return {};
  }
  const includes = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= filterLevel + 1; ++i) {
    if (filterKeys[i]) {
      includes[`includes${filterKeys[i]}`] = true;
    }
  }
  return {
    ...where,
    ...includes,
  };
};

export const columns = [
  {
    width: 120,
    flexShrink: 0,
    label: 'Metso id',
    dataKey: 'metso_id',
    numeric: true,
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Replace QTY',
    dataKey: 'replace_quantity',
    numeric: true,
  },
  {
    width: 220,
    flexShrink: 0,
    label: 'Description',
    dataKey: 'description',
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Catalog id',
    dataKey: 'catalog_id',
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Crusher',
    dataKey: 'crusher',
  },
  {
    width: 180,
    flexShrink: 0,
    label: 'OEM Reference',
    dataKey: 'oem',
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Group',
    dataKey: 'group',
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Crusher type',
    dataKey: 'crusher_type',
  },
  {
    width: 120,
    flexShrink: 0,
    label: 'Brand',
    dataKey: 'brand',
  },
];

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
`;

const MobileFilterWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Products = ({ location, scrollableDiv, history }) => {
  // Update page title
  const updateTitle = useStoreActions(dispatch => dispatch.page.updateTitle);
  updateTitle('Products');
  // parse search params
  const where = qs.parse(location.search, { ignoreQueryPrefix: true });

  // Fetch distinct filters by selected value
  const { data, loading, error } = useQuery(GET_PRODUCTS, {
    variables: getVariables(where),
  });

  const { products, productsAggregate, ...filtersList } = data || {
    products: [],
  };

  const productsWithOrderQuantity = products.map(p => ({
    ...p,
    order_quantity: null,
  }));

  if (error) {
    return <h1>Error occured</h1>;
  }
  if (!data && loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <FilterContainer>
        <ContentWrapper>
          <MediaQuery minWidth={768}>
            {matches => {
              if (matches) {
                return (
                  <Fragment>
                    <FilterTitle>Filter</FilterTitle>
                    <Filters where={where} filters={filtersList} />
                  </Fragment>
                );
              }
              return (
                <MobileFilterWrapper>
                  <FilterTitle>Filter a wear parts from catalogue</FilterTitle>
                  <MobileFilters where={where} filters={filtersList} />
                </MobileFilterWrapper>
              );
            }}
          </MediaQuery>
        </ContentWrapper>
      </FilterContainer>
      {scrollableDiv && scrollableDiv.current && (
        <TableWrapper>
          <ContentWrapper>
            {loading ? (
              <Loading />
            ) : (
              <Table
                showCollect
                productsCount={
                  idx(productsAggregate, _ => _.aggregate.count) || 0
                }
                data={productsWithOrderQuantity}
                columns={columns}
                scrollableDiv={scrollableDiv}
                onRowClick={p => history.push(`product/${p.rowData.id}`)}
                location={location}
              />
            )}
          </ContentWrapper>
        </TableWrapper>
      )}
    </Fragment>
  );
};

Products.propTypes = {
  scrollableDiv: oneOfType([func, shape({ current: instanceOf(Element) })])
    .isRequired,
  history: shape({}).isRequired,
};

Products.propTypes = {
  location: shape({}).isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

export default withStyles(styles)(Products);
