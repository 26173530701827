import { gql } from 'apollo-boost';

export default gql`
  query searchProducts($search: String!) {
    search_products(limit: 100, args: { search: $search }) {
      id
      crusher
      metso_id
      catalog_id
      description
      oem
      crusher_type
      brand
    }
  }
`;
