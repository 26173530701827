import { lazy } from 'react';

import Products from './containers/Products';
import Login from './containers/Login';
import Callback from './components/Callback';

const Collection = lazy(() => import('./components/Collection'));
const UploadPage = lazy(() => import('./containers/UploadFile'));
const SingleProductContainer = lazy(() =>
  import('./containers/SingleProductContainer'),
);
const NotFound = lazy(() => import('./components/404Page'));

export default [
  {
    path: '/',
    exact: true,
    component: Products,
    private: true,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/callback',
    component: Callback,
  },
  {
    path: '/import',
    exact: true,
    component: UploadPage,
    private: true,
    superPrivate: 'manager',
  },
  {
    path: '/collection',
    exact: true,
    component: Collection,
    private: true,
  },
  {
    path: '/product/:productId',
    component: SingleProductContainer,
    exact: true,
    private: true,
  },
  {
    path: '*',
    component: NotFound,
  },
];
