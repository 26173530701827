import { useState, useEffect, useCallback } from 'react';

const getSize = el => {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }
  return {
    height: el.offsetHeight,
    width: el.offsetWidth,
    scrollBarWidth:
      el.offsetWidth - (el.childNodes.length && el.childNodes[0].offsetWidth) ||
      15,
  };
};

const useElementSize = ref => {
  const [elementSize, setElementSize] = useState(
    getSize(ref && ref.current ? ref.current : {}),
  );
  const handleResize = useCallback(() => {
    if (ref.current) {
      setElementSize(getSize(ref.current));
    }
  }, [ref]);

  useEffect(() => {
    const currentRef = ref.current;
    handleResize();
    window.addEventListener('resize', () =>
      setElementSize(getSize(ref && ref.current ? ref.current : {})),
    );
    return () =>
      window.removeEventListener('resize', () =>
        setElementSize(getSize(ref && currentRef ? currentRef : {})),
      );
  }, [ref, handleResize]);
  return elementSize;
};

export default useElementSize;
