import React from 'react';
import { node } from 'prop-types';
import styled from 'styled-components/macro';

const StyledContentWrapper = styled.div`
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  align-items: flex-start;
  flex-shrink: 0;
  flex-grow: 1;
`;

const ContentWrapper = ({ children }) => (
  <StyledContentWrapper>{children}</StyledContentWrapper>
);

ContentWrapper.propTypes = {
  children: node.isRequired,
};

export default ContentWrapper;
