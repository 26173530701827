import { action } from 'easy-peasy';
import uniqby from 'lodash.uniqby';
import { toast } from 'react-toastify';

const getIncrement = (quantities, item) => {
  const customQty = quantities.find(x => x.id === item.id);
  return customQty ? customQty.orderQuantity : item.replace_quantity;
};

const getCollectedQty = (collected, item) =>
  collected.find(x => x.id === item.id).order_quantity;

const model = {
  page: {
    title: '',
    updateTitle: action((state, payload) => {
      // eslint-disable-next-line
      state.title = payload;
    }),
  },
  collection: {
    collected: JSON.parse(localStorage.getItem('collected')) || [],
    add: action((state, payload) => {
      toast.success('Collected', { position: toast.POSITION.TOP_CENTER });
      const { selected, quantities } = payload;
      const { collected } = state;

      // new to add
      const newItemsWithQty = selected
        .filter(sel => !collected.map(col => col.id).includes(sel.id))
        .map(item => ({
          ...item,
          order_quantity: getIncrement(quantities, item),
        }));

      // existing to update
      const existingItemsWithQty = selected
        .filter(sel => collected.map(col => col.id).includes(sel.id))
        .map(item => ({
          ...item,
          order_quantity:
            parseInt(getCollectedQty(collected, item), 10) +
            parseInt(getIncrement(quantities, item), 10),
        }));

      // the ordering is important, do not change
      const all = [...existingItemsWithQty, ...collected, ...newItemsWithQty];
      const uniqueItems = uniqby(all, 'id');

      localStorage.setItem('collected', JSON.stringify(uniqueItems));
      return {
        ...state,
        collected: uniqueItems,
      };
    }),
    modify: action((state, payload) => {
      const { collected } = state;
      const { id, orderQuantity } = payload;
      const updated = collected.map(item => {
        if (item.id === id) {
          return {
            ...item,
            order_quantity: orderQuantity,
          };
        }
        return item;
      });
      localStorage.setItem('collected', JSON.stringify(updated));
      return {
        ...state,
        collected: updated,
      };
    }),
    remove: action((state, payload) => {
      toast.success('Removed', { position: toast.POSITION.TOP_CENTER });
      if (Array.isArray(payload)) {
        const newData = state.collected.filter(i => !payload.includes(i.id));
        localStorage.setItem('collected', JSON.stringify(newData));
        // eslint-disable-next-line
        state.collected = newData;
      } else {
        const newData = state.collected.filter(i => i.id !== payload);
        localStorage.setItem('collected', JSON.stringify(newData));
        // eslint-disable-next-line
        state.collected = newData;
      }
    }),
  },
  search: {
    isSearchOpen: false,
    toggleSearch: action((state, payload) => {
      // eslint-disable-next-line
      state.isSearchOpen = payload;
    }),
  },
  upload: {
    activeStep: 0,
    fileData: null,
    loading: false,
    updateStep: action((state, payload) => {
      // eslint-disable-next-line
      state.activeStep = payload;
    }),
    setFileData: action((state, payload) => {
      // eslint-disable-next-line
      state.fileData = payload;
    }),
    setLoading: action((state, payload) => {
      // eslint-disable-next-line
      state.loading = payload;
    }),
  },
};

export default model;
