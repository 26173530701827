import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  number,
  arrayOf,
  string,
  oneOfType,
  shape,
  func,
  bool,
} from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  TableCell as MuiTableCell,
  TableSortLabel,
  Checkbox,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import xor from 'lodash.xor';
import orderby from 'lodash.orderby';
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from 'styled-components/macro';
import { Flex } from 'rebass';
import { useStoreActions } from 'easy-peasy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import XLSX from 'xlsx';

import logo2 from '../../assets/Metso-Contender-Series_w700.jpg';
import useElementSize from '../../hooks/useElementSize';
import QuantityInput from '../QuantityInput';

const TableCell = styled(MuiTableCell)`
  flex-shrink: 0;
  flex: 1 0 0;
  display: flex;
  padding-left: 10px;
  padding-right: 10px !important;
  height: ${(props) => (props.height ? `${props.height}px` : '56px')};
`;

const CellValue = styled.span`
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TableBarButtons = styled(Flex)`
  // width: 35%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
`;

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    flex: '1 1 0',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:focus': {
      outline: 0,
    },
  },
});

const StyledToolbar = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:focus {
    outline: 0;
  }
`;

const StyledTable = styled(Table)`
  font-family: ${(props) => props.theme.typography.fontFamily};
  .ReactVirtualized__Table__headerRow {
    background: white;
    position: sticky;
    top: 0;
  }
  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    flex-shrink: 0 !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .ReactVirtualized__Table__row {
    width: auto;
  }
  .ReactVirtualized__Table__headerRow {
    width: 100%;
  }
`;

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  color: inherit;
`;

const TitleField = styled(Field)`
  padding: 10px;
  outline: none;
  flex: 2;
`;

const CloseIconButton = styled(IconButton)`
  display: flex;
  margin-left: auto;
`;

const ModalForm = styled.form`
  display: inline-flex;
  justify-content: space-around;
`;

const ModalTitle = styled.p`
  margin-bottom: 50px;
`;

const customModalStyles = {
  content: {
    width: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  zIndex: '3',
};

const getRowClassName = ({ index, classes, rowClassName, onRowClick }) =>
  classNames(classes.tableRow, classes.flexContainer, rowClassName, {
    [classes.tableRowHover]: index !== -1 && onRowClick != null,
  });

const makePDF = (body, name) => {
  const columns = [
    { header: 'Metso Id', dataKey: 'metso_id' },
    { header: 'Replace QTY', dataKey: 'replace_quantity' },
    { header: 'Description', dataKey: 'description' },
    { header: 'Catalog Id', dataKey: 'catalog_id' },
    { header: 'Crusher', dataKey: 'crusher' },
    { header: 'OEM Reference', dataKey: 'oem' },
    { header: 'Group', dataKey: 'group' },
    { header: 'Crusher Type', dataKey: 'crusher_type' },
    { header: 'Brand', dataKey: 'brand' },
  ];

  const date = format(new Date(), ' - DD.MM.YYYY - HH:mm');
  const title = name + date;

  // eslint-disable-next-line
  const doc = new jsPDF('l', 'mm', 'a3');
  doc.autoTable({
    columns,
    body,
    didDrawPage: () => {
      // Header
      doc.setFontSize(15);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.addImage(logo2, 'JPG', 12, 3, 100, 12);

      doc.text(title, 185, 9);
    },
    margin: { top: 25 },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontSize: 10,
    },
    columnStyles: {
      metso_id: {
        minCellWidth: 25,
      },
      replace_quantity: {
        minCellWidth: 30,
      },
      description: {
        minCellWidth: 80,
      },
      catalog_id: {
        minCellWidth: 20,
      },
      crusher: {
        minCellWidth: 25,
      },
      group: {
        minCellWidth: 30,
      },
      crusher_type: {
        minCellWidth: 30,
      },
      oem: {
        minCellWidth: 35,
      },
      brand: {
        minCellWidth: 30,
      },
    },
  });
  doc.autoTable({ html: '#products-pdf' });
  doc.save(`${title}.pdf`);
};

const HEADERS_NO_QTY = [
  ['metso_id', 'METSO ID'],
  ['replace_quantity', 'REPLACE QTY'],
  ['description', 'DESCRIPTION'],
  ['catalog_id', 'CATALOG ID'],
  ['crusher', 'CRUSHER'],
  ['oem', 'OEM REFERENCE'],
  ['group', 'GROUP'],
  ['crusher_type', 'CRUSHER TYPE'],
  ['brand', 'BRAND'],
];

const HEADERS_COMPLETE = HEADERS_NO_QTY.slice();
HEADERS_COMPLETE.push(['order_quantity', 'QTY']);

const HEADERS_MY_METSO = [
  ['metso_id', 'Metso ID'],
  ['order_quantity', 'QTY'],
  ['oem', 'OEM REFERENCE'],
  ['description', 'DESCRIPTION'],
];

const aggregateRows = (selected) =>
  selected.reduce((acc, curr) => {
    const existing = acc.find((item) => item.metso_id === curr.metso_id);
    if (existing) {
      return [
        {
          ...existing,
          order_quantity: existing.order_quantity + curr.order_quantity,
        },
        ...acc.filter((item) => item.metso_id !== curr.metso_id),
      ];
    }
    return [...acc, curr];
  }, []);

const renderCSVComplete = (selected, showCollect) => {
  const headers = showCollect ? HEADERS_NO_QTY : HEADERS_COMPLETE;
  const CSVHeadrs = headers.map((header) => header[1]);
  const objectKeys = headers.map((header) => header[0]);
  const rows = selected.map((item) => objectKeys.map((key) => item[key]));
  return [CSVHeadrs, ...rows];
};

const downloadMyMetsoXLSX = (selected) => {
  const fileName = 'my-metso.xlsx';
  const aggregated = aggregateRows(selected);
  const objectKeys = HEADERS_MY_METSO.map((header) => header[0]);
  const ws = XLSX.utils.json_to_sheet(
    aggregated.map((item) => objectKeys.map((key) => item[key])),
    { skipHeader: 1 },
  );
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  return XLSX.writeFile(wb, fileName);
};

const EnhancedTableToolbar = ({
  data,
  selected = [],
  showRemove,
  showCollect,
  productsCount,
  quantities,
  setSelected,
}) => {
  const addToCollection = useStoreActions((actions) => actions.collection.add);
  const removeFromCollection = useStoreActions(
    (actions) => actions.collection.remove,
  );

  const [isModalOpen, setModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (value) => {
    makePDF(selected, value.pdfName);
    setModal(false);
  };

  const remove = () => {
    const ids = selected.map((i) => i.id);
    removeFromCollection(ids);
    setSelected([]);
  };

  return (
    <StyledToolbar>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModal(false)}
        style={customModalStyles}
      >
        <CloseIconButton onClick={() => setModal(false)}>
          <CloseIcon />
        </CloseIconButton>
        <ModalTitle>Please enter a name for your exported PDF.</ModalTitle>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <ModalForm onSubmit={handleSubmit}>
              <TitleField
                name="pdfName"
                component="input"
                placeholder="Your PDF name..."
              />
              <Button type="submit" disabled={!selected.length}>
                Export PDF
              </Button>
            </ModalForm>
          )}
        />
      </Modal>
      <div className="title">
        {selected.length > 0 ? (
          <Typography variant="h6" id="tableTitleSelection">
            {selected.length} / {data.length} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {productsCount} {productsCount === 1 ? 'Product' : 'Products'}
          </Typography>
        )}
      </div>
      <TableBarButtons>
        {showCollect && (
          <Button
            size="small"
            disabled={!selected.length}
            onClick={() => addToCollection({ selected, quantities })}
          >
            COLLECT
          </Button>
        )}
        <div>
          {showRemove && (
            <Button
              disabled={!selected.length}
              variant="outlined"
              size="small"
              onClick={() => remove(selected)}
            >
              REMOVE
            </Button>
          )}
        </div>
        <div>
          <IconButton
            aria-label="More"
            aria-owns={open ? 'table-top-menu' : undefined}
            aria-haspopup="true"
            // eslint-disable-next-line
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="table-top-menu"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
          >
            <MenuItem
              onClick={() => {
                setModal(true);
                handleClose();
              }}
              disabled={selected.length < 1}
            >
              EXPORT PDF
            </MenuItem>
            {!showCollect && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  downloadMyMetsoXLSX(selected);
                }}
                disabled={selected.length < 1}
              >
                EXPORT XLSX FOR MY METSO
              </MenuItem>
            )}
            <MenuItem onClick={handleClose} disabled={selected.length < 1}>
              <StyledCSVLink
                separator=";"
                enclosingCharacter={`"`}
                data={renderCSVComplete(selected, showCollect)}
                asyncOnClick
                filename="products-list.csv"
              >
                EXPORT CSV
              </StyledCSVLink>
            </MenuItem>
          </Menu>
        </div>
      </TableBarButtons>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  showRemove: bool.isRequired,
  showCollect: bool.isRequired,
  data: arrayOf(shape({})),
  selected: arrayOf(shape([])),
  productsCount: number,
  quantities: arrayOf(shape([])).isRequired,
  setSelected: func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  data: [],
  selected: [],
  productsCount: null,
};

export const cellRenderer = ({ cellData }) => (
  <TableCell
    component="div"
    variant="body"
    style={{ alignItems: 'center', overflow: 'hidden', height: '100%' }}
  >
    <CellValue>{cellData}</CellValue>
  </TableCell>
);

cellRenderer.propTypes = {
  cellData: oneOfType([number, string, shape({})]).isRequired,
};

const sortBySetter = ({ sortBy, setSortBy, sort, setSort, dataKey }) => {
  if (dataKey === sortBy) {
    return setSort(sort === 'asc' ? 'desc' : 'asc');
  }
  setSortBy(dataKey);
  return setSort('desc');
};

export const headerRenderer = ({
  label,
  columnIndex,
  dataKey,
  sortBy,
  setSortBy,
  sort,
  setSort,
  headerHeight,
  columns,
}) => {
  const inner =
    !columns[columnIndex].disableSort && sort != null ? (
      <TableSortLabel active={dataKey === sortBy} direction={sort}>
        <CellValue>{label}</CellValue>
      </TableSortLabel>
    ) : (
      <CellValue>label</CellValue>
    );

  return (
    <TableCell
      component="div"
      variant="head"
      height={headerHeight}
      onClick={() =>
        sortBySetter({ sortBy, setSortBy, sort, setSort, dataKey })
      }
    >
      {inner}
    </TableCell>
  );
};

headerRenderer.propTypes = {
  label: string.isRequired,
  columnIndex: number.isRequired,
  dataKey: string.isRequired,
  sortBy: string.isRequired,
  setSortBy: func.isRequired,
  sort: string.isRequired,
  setSort: func.isRequired,
  headerHeight: number.isRequired,
  columns: arrayOf(shape({})).isRequired,
};

const checkboxCellRenderer = ({
  classes,
  onRowClick,
  setSelected,
  selected,
  rowData,
}) => (
  <TableCell
    onClick={(e) => e.stopPropagation()}
    component="div"
    className={classNames(classes.tableCell, classes.flexContainer, {
      [classes.noClick]: onRowClick == null,
    })}
    variant="body"
    style={{
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'visible',
      height: '100%',
    }}
  >
    <Checkbox
      checked={selected.includes(rowData)}
      onChange={() => setSelected((current) => xor(current, [rowData]))}
    />
  </TableCell>
);

checkboxCellRenderer.propTypes = {
  classes: shape({}).isRequired,
  onRowClick: func.isRequired,
  setSelected: func.isRequired,
  selected: arrayOf(number).isRequired,
  rowData: shape({}).isRequired,
};

const checkboxHeaderRenderer = ({
  classes,
  onRowClick,
  headerHeight,
  setSelected,
  selected,
  data,
}) => (
  <TableCell
    component="div"
    className={classNames(classes.tableCell, classes.flexContainer, {
      [classes.noClick]: onRowClick == null,
    })}
    variant="body"
    style={{ height: headerHeight, paddingLeft: '10px', paddingRight: '10px' }}
  >
    <Checkbox
      checked={data.length === selected.length && data.length !== 0}
      indeterminate={selected.length > 0 && selected.length < data.length}
      onChange={() =>
        selected.length < data.length
          ? setSelected(data.map((i) => i))
          : setSelected([])
      }
    />
  </TableCell>
);

checkboxHeaderRenderer.propTypes = {
  classes: shape({}).isRequired,
  onRowClick: func.isRequired,
  headerHeight: number.isRequired,
  setSelected: func.isRequired,
  selected: arrayOf(number).isRequired,
  data: arrayOf(shape({})).isRequired,
};

const inputHeaderRenderer = ({ classes, onRowClick, headerHeight }) => (
  <TableCell
    component="div"
    className={classNames(classes.tableCell, classes.flexContainer, {
      [classes.noClick]: onRowClick == null,
    })}
    variant="body"
    style={{
      height: headerHeight,
      paddingLeft: '10px',
      paddingRight: '10px',
    }}
  >
    Order quantity
  </TableCell>
);

inputHeaderRenderer.propTypes = {
  classes: shape({}).isRequired,
  onRowClick: func.isRequired,
  headerHeight: number.isRequired,
};

const changeValue = ({
  value,
  setQuantities,
  modifyCollection,
  quantities,
  showCollect,
  rowData,
}) => {
  const qty = parseInt(value, 10);
  const existing = quantities.find((item) => item.id === rowData.id);
  if (showCollect) {
    if (!existing) {
      setQuantities([...quantities, { id: rowData.id, orderQuantity: qty }]);
    } else {
      const keep = quantities.filter((item) => item.id !== rowData.id);
      setQuantities([...keep, { id: rowData.id, orderQuantity: qty }]);
    }
  } else {
    modifyCollection({ id: rowData.id, orderQuantity: qty });
  }
};

const inputCellRenderer = ({
  classes,
  rowData,
  onRowClick,
  setQuantities,
  modifyCollection,
  quantities,
  showCollect,
}) => {
  return (
    <TableCell
      component="div"
      onClick={(e) => e.stopPropagation()}
      className={classNames(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: onRowClick == null,
      })}
      variant="body"
      style={{
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '0px',
        paddingBottom: '0px',
        overflow: 'visible',
        height: '100%',
      }}
    >
      <QuantityInput
        setQuantities={setQuantities}
        modifyCollection={modifyCollection}
        quantities={quantities}
        showCollect={showCollect}
        rowData={rowData}
        changeValue={changeValue}
      />
    </TableCell>
  );
};

inputCellRenderer.propTypes = {
  classes: shape({}).isRequired,
  onRowClick: func.isRequired,
  rowData: shape({}).isRequired,
  setQuantities: func.isRequired,
  quantities: arrayOf(shape([])).isRequired,
  showCollect: bool.isRequired,
  modifyCollection: func.isRequired,
};

const HorisontalScroll = styled.div`
  overflow-x: auto;
`;

const getRowHeight = ({ index }, data) => {
  const desc = data && data[index] && data[index].description;
  const rows = Math.ceil(desc.length / 31);
  const height = rows > 1 ? 40 + rows * 10 : 40;
  return height;
};

const MyTable = ({
  data,
  productsCount,
  columns,
  scrollableDiv,
  classes,
  onRowClick,
  headerHeight,
  location,
  showRemove,
  showCollect,
  hideToolbar,
  hideCheckbox,
  sort: initialSort,
  ...rest
}) => {
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState(initialSort || 'desc');
  const [sortBy, setSortBy] = useState('id');
  const [sortedData, setSortedData] = useState([]);
  const { height, scrollBarWidth } = useElementSize(scrollableDiv);
  const [quantities, setQuantities] = useState([]);
  const modifyCollection = useStoreActions(
    (actions) => actions.collection.modify,
  );
  const tableRef = useRef();
  useEffect(() => {
    const sortData = orderby(data, [sortBy], [sort]);
    setSortedData(sortData);
    if (tableRef && tableRef.current) {
      tableRef.current.recomputeRowHeights();
    }
  }, [data, sortBy, sort]);
  return (
    <>
      {!hideToolbar && (
        <EnhancedTableToolbar
          setSelecte={setSelected}
          quantities={quantities}
          productsCount={productsCount}
          showCollect={showCollect}
          showRemove={showRemove}
          data={sortedData}
          selected={selected}
          setSelected={setSelected}
          location={location}
        />
      )}
      <HorisontalScroll>
        <AutoSizer disableHeight>
          {({ width }) => (
            <StyledTable
              ref={tableRef}
              height={height - scrollBarWidth}
              width={Math.max(width, 1460)}
              overscanRowCount={10}
              rowCount={sortedData.length}
              rowGetter={({ index }) => sortedData[index]}
              rowClassName={(props) =>
                getRowClassName({ ...props, classes, onRowClick })
              }
              rowHeight={(props) =>
                (sortedData.length && getRowHeight(props, sortedData)) || 50
              }
              headerHeight={headerHeight}
              onRowClick={onRowClick}
              {...rest}
            >
              {!hideToolbar && (
                <Column
                  key="checkbox"
                  dataKey="checkbox"
                  width={70}
                  flexShrink={0}
                  overflow="visible"
                  style={{ height: '100%' }}
                  headerRenderer={(props) =>
                    checkboxHeaderRenderer({
                      ...props,
                      classes,
                      headerHeight,
                      onRowClick,
                      selected,
                      setSelected,
                      data,
                    })
                  }
                  cellRenderer={(props) =>
                    checkboxCellRenderer({
                      ...props,
                      classes,
                      onRowClick,
                      selected,
                      setSelected,
                    })
                  }
                />
              )}
              {columns.map(
                (
                  {
                    cellContentRenderer = cellRenderer,
                    className,
                    dataKey,
                    ...other
                  },
                  index,
                ) => (
                  <Column
                    key={dataKey}
                    dataKey={dataKey}
                    style={{ height: '100%' }}
                    headerRenderer={(headerProps) =>
                      headerRenderer({
                        ...headerProps,
                        headerHeight,
                        columns,
                        classes,
                        onRowClick,
                        sort,
                        sortBy,
                        setSortBy,
                        setSort,
                        columnIndex: index,
                      })
                    }
                    cellRenderer={(props) => {
                      return cellContentRenderer({
                        ...props,
                        classes,
                        columns,
                        onRowClick,
                        columnIndex: index,
                      });
                    }}
                    {...other}
                  />
                ),
              )}
              <Column
                key="qty"
                dataKey="qty"
                flexShrink={0}
                width={200}
                ovetflow="visible"
                style={{ height: '100%' }}
                headerRenderer={(props) =>
                  inputHeaderRenderer({
                    ...props,
                    classes,
                    onRowClick,
                    headerHeight,
                  })
                }
                cellRenderer={({ rowData, ...props }) =>
                  inputCellRenderer({
                    ...props,
                    classes,
                    rowData,
                    onRowClick,
                    quantities,
                    setQuantities,
                    modifyCollection,
                    showCollect,
                  })
                }
              />
            </StyledTable>
          )}
        </AutoSizer>
      </HorisontalScroll>
    </>
  );
};

MyTable.propTypes = {
  classes: shape({}).isRequired,
  onRowClick: func.isRequired,
  data: arrayOf(shape({})),
  columns: arrayOf(shape()).isRequired,
  scrollableDiv: shape().isRequired,
  headerHeight: number,
  sort: string,
  location: shape({}),
  showRemove: bool,
  showCollect: bool,
  hideToolbar: bool,
  hideCheckbox: bool,
  productsCount: number,
};

MyTable.defaultProps = {
  headerHeight: 50,
  sort: 'desc',
  data: [],
  location: {},
  showRemove: false,
  showCollect: false,
  hideToolbar: false,
  hideCheckbox: false,
  productsCount: null,
};

const WrapperMyTable = withStyles(styles)(MyTable);

export default WrapperMyTable;
