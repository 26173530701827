import React, { Fragment } from 'react';
import styled from 'styled-components';
import { func, shape, bool, arrayOf } from 'prop-types';

import { allColors } from '../../constants/styles';

const AdjustButton = styled.button`
  width: 25px;
  max-height: 40px;
  min-height: 30px;
  text-align: center;
  border-color: ${allColors.lineDark};
  background: ${allColors.white};
  &:active {
    border: 1px solid;
    background: ${allColors.terracotta};
    color: ${allColors.white};
  }
  &:hover {
    border: 1px solid;
  }
`;

const Input = styled.input`
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-right: none;
  border-left: none;
  border-color: ${allColors.lineDark};
  min-height: 30px;
  max-height: 40px;
  width: 40px;
  align-content: center;
  text-align: center;
`;

const QuantityInput = ({
  setQuantities,
  modifyCollection,
  quantities,
  showCollect,
  rowData,
  changeValue,
  singleProduct,
}) => {
  const ref = React.createRef();
  const defaults = {
    setQuantities,
    modifyCollection,
    quantities,
    showCollect,
    rowData,
  };
  const rowObj = quantities.find(row => row.id === rowData.id);
  const currValue =
    (rowObj && rowObj.orderQuantity) ||
    rowData.order_quantity ||
    rowData.replace_quantity;

  return (
    <Fragment>
      <AdjustButton
        onClick={() => {
          if (ref.current.value > 1) {
            ref.current.value = parseInt(ref.current.value, 10) - 1;
            if (singleProduct) {
              changeValue(ref.current.value);
            } else {
              changeValue({
                ...defaults,
                value: ref.current.value,
              });
            }
          }
        }}
      >
        -
      </AdjustButton>
      {singleProduct && (
        <Input
          ref={ref}
          inputProps={{ style: { textAlign: 'center' } }}
          disableUnderline
          defaultValue={currValue} // will make the input uncontrolled
          onChange={({ target: { value } }) => {
            return changeValue(value);
          }}
        />
      )}
      {!singleProduct && (
        <Input
          ref={ref}
          inputProps={{ style: { textAlign: 'center' } }}
          disableUnderline
          value={currValue} // will make the input controlled
          onChange={({ target: { value } }) => {
            changeValue({
              ...defaults,
              value,
            });
          }}
        />
      )}
      <AdjustButton
        onClick={() => {
          ref.current.value = parseInt(ref.current.value, 10) + 1;
          if (singleProduct) {
            return changeValue(ref.current.value);
            // eslint-disable-next-line no-else-return
          } else {
            changeValue({
              ...defaults,
              value: ref.current.value,
            });
          }
        }}
      >
        +
      </AdjustButton>
    </Fragment>
  );
};

QuantityInput.propTypes = {
  rowData: shape({}).isRequired,
  showCollect: bool.isRequired,
  changeValue: func.isRequired,
  singleProduct: bool,
  quantities: arrayOf(shape({})),
  setQuantities: func,
  modifyCollection: func,
};

QuantityInput.defaultProps = {
  quantities: [],
  setQuantities: () => {},
  modifyCollection: () => {},
  singleProduct: false,
};

export default QuantityInput;
