import React, { Fragment, Suspense, useRef } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider, createStore, useStoreState } from 'easy-peasy';
// hack to fix overriding jss
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import LogRocket from 'logrocket';

import PrivateRoute from './containers/PrivateRoute';
import NavBar from './components/NavBar';
import theme from './constants/themes';
import model from './model';
import client from './containers/Apollo';
import routes from './routes';
import Loading from './components/Loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('metso/contender-catalog-app', {
    network: {
      requestSanitizer: request => {
        if (request.url.toLowerCase().indexOf('idToken' !== -1)) {
          request.url = null;
        }
        request.headers.Authorization = null;
        request.headers.authorization = null;
        return request;
      },
    },
  });
}

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point'),
});

toast.configure();

const PageArea = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
`;

const store = createStore(model);

const App = () => {
  const title = useStoreState(state => state.page.title);
  const scrollableDiv = useRef(null);
  return (
    <Fragment>
      <ApolloProvider client={client}>
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <ThemeProvider
            theme={{ ...theme, breakpoints: ['40em', '52em', '64em'] }}
          >
            <MuiThemeProvider theme={theme}>
              <Router>
                <Fragment>
                  <Route
                    path="*"
                    render={props => <NavBar title={title} {...props} />}
                  />
                  <Suspense fallback={<Loading />}>
                    <PageArea ref={scrollableDiv}>
                      <Switch>
                        {routes.map(
                          ({
                            private: isPrivate,
                            path,
                            exact,
                            component,
                            superPrivate,
                          }) =>
                            isPrivate ? (
                              <PrivateRoute
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                                scrollableDiv={scrollableDiv && scrollableDiv}
                                superPrivate={superPrivate && superPrivate}
                              />
                            ) : (
                              <Route
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                              />
                            ),
                        )}
                      </Switch>
                    </PageArea>
                  </Suspense>
                </Fragment>
              </Router>
            </MuiThemeProvider>
          </ThemeProvider>
        </JssProvider>
      </ApolloProvider>
    </Fragment>
  );
};

const Root = () => (
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);

export default Root;
