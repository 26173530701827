import React, { useState, useEffect, Fragment } from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import xor from 'lodash.xor';
import styled from 'styled-components/macro';
import {
  ListItemText,
  List,
  ListItem,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Dialog,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { buildLink, filterKeys } from '../Filters';

const StyledList = styled(List)`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0;
  &&.last-list a.last-selected {
    background: ${props => props.theme.palette.primary.main};
    span {
      color: white;
    }
  }
`;

const FilterListScroller = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

const FilterListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledListItem = styled(ListItem)`
  span {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const PanelTitle = styled(Typography)`
  text-transform: uppercase;
  font-size: 16px;
  flex-basis: ${1 / 3}%;
  flex-shrink: 0;
`;

const PanelSubtitle = styled(Typography)`
  font-size: 16px;
  color: ${props => props.theme.palette.text.secondary};
  align-self: flex-end;
`;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  & > div {
    justify-content: space-between;
  }
`;

const Transition = props => <Slide direction="up" {...props} />;

const renderFilters = ({ where, filters, expanded, setExpanded }) =>
  Object.keys(filters).map((key, index) => {
    const currentLevel = filterKeys.indexOf(key);
    if (currentLevel === -1) {
      return null;
    }
    if (filterKeys.indexOf(key) === filterKeys[currentLevel - 1]) {
      return null;
    }
    if (!filters[key] || !filters[key][0] || !filters[key][0].value) {
      return null;
    }
    return (
      <Fragment key={key}>
        <ExpansionPanel
          expanded={!expanded.includes(key)}
          onChange={() => null}
        >
          <StyledExpansionPanelSummary
            onClick={e => {
              e.preventDefault();
              const newArr = xor(expanded, [key]);
              setExpanded(newArr);
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <PanelTitle>{key}</PanelTitle>
            <PanelSubtitle>{where[key]}</PanelSubtitle>
          </StyledExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FilterListWrapper key={key}>
              <FilterListScroller>
                <StyledList
                  component="nav"
                  className={
                    Object.keys(where).length - 1 === index ? 'last-list' : ''
                  }
                >
                  {filters[key]
                    .filter(i => i.id !== null || i.id !== undefined)
                    .map(f =>
                      f.value ? (
                        <StyledListItem
                          key={f.id}
                          button
                          component={Link}
                          to={buildLink(where, key, { [key]: f.value })}
                          onClick={() => {
                            const newArray = expanded;
                            newArray.push(key);
                            setExpanded(newArray);
                          }}
                          selected={where[key] === f.value}
                          className={
                            where[key] === f.value ? 'last-selected' : ''
                          }
                        >
                          <ListItemText primary={f.value} />
                        </StyledListItem>
                      ) : null,
                    )}
                </StyledList>
              </FilterListScroller>
            </FilterListWrapper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    );
  });

const ScrollableArea = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Filters = ({ where, filters }) => {
  const [expanded, setExpanded] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setExpanded(Object.keys(where) || []);
  }, [where]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setOpen(state => !state)}
      >
        Start
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="default" position="relative">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Filter
            </Typography>
          </Toolbar>
        </AppBar>
        <ScrollableArea>
          {renderFilters({
            where,
            filters,
            expanded,
            setExpanded,
            handleClose,
          })}
        </ScrollableArea>
      </Dialog>
    </div>
  );
};

Filters.propTypes = {
  where: shape({}),
  filters: shape({}),
};

Filters.defaultProps = {
  where: {},
  filters: [],
};

export default Filters;
