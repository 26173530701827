import React, { Fragment } from 'react';
import { shape, string } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import CloudIcon from '@material-ui/icons/CloudUploadOutlined';
import styled from 'styled-components/macro';
import { withRouter, NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Button from '@material-ui/core/Button';
import { useStoreActions, useStoreState } from 'easy-peasy';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import Fab from '@material-ui/core/Fab';
import { Flex, Text } from 'rebass';
import darken from 'polished/lib/color/darken';

import SearchBar from '../SearchBar';
import MobileSearchBar from '../MobileSearchBar';
import { isAuthenticated, logOut } from '../../utils/auth';
import withAuth from '../../containers/withAuth';
import { allColors, colors } from '../../constants/styles';

const StyledAppBar = styled(AppBar)`
  flex-shrink: 0;
  min-height: 64px !important;
`;
const StyledIconButton = styled(IconButton)`
  margin-right: 10px;
`;

const drawerWidth = 280;

const StyledDrawer = styled(Drawer)`
  > div {
    width: ${drawerWidth}px;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: flex-end;
  min-height: 64px !important;
`;

const StyledToolbar = styled(Toolbar)`
  min-height: 64px !important;
  display: inline-flex;
  justify-content: space-between;
`;

const LogoutButton = styled(Button)`
  position: absolute;
  bottom: 80px;
  left: 20px;
  width: 95px;
  background: transparent;
  color: white;
  border-color: white;
  &:hover {
    color: black;
    background: white;
  }
`;

const StyledList = styled(List)`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0px;
  overflow-y: auto;
  background: ${allColors.turquoiseGreen};
  flex-direction: column;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 9;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  will-change: transform;
  transition: transform 0.3s;
`;

const Profile = styled(Flex)`
  background: ${allColors.green};
`;

const ProfileAvatar = styled.div`
  color: ${allColors.white};
  background: ${allColors.turquoiseGreen};
  border-radius: 50%;
  display: flex;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
`;

const User = styled(Text)`
  color: ${allColors.white};
  font-size: 1rem;
  font-weight: lighter;
`;

const StyledLink = styled(ListItem)`
  text-decoration: none;
  padding: 20px;
  display: flex;
  color: ${allColors.white};
  &:hover {
    text-decoration: none;
    color: ${allColors.white};
    background: ${darken(0.05, allColors.green)};
  }
  &.selected {
    background: ${colors.primaryColor};
    color: ${allColors.white};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  color: ${allColors.white};
`;

const NavBar = ({ title, match, history, user }) => {
  const [open, setOpen] = React.useState(false);
  const collection = useStoreState(state => state.collection.collected);
  const isSearchOpen = useStoreState(state => state.search.isSearchOpen);
  const isCollectionPage = match.url.startsWith('/collection');
  const isImportPage = match.url.startsWith('/import');
  const isProductPage = match.url.startsWith('/product');
  const activeStep = useStoreState(state => state.upload.activeStep);
  const updateStep = useStoreActions(dispatch => dispatch.upload.updateStep);
  const fileData = useStoreState(state => state.upload.fileData);
  const loading = useStoreState(state => state.upload.loading);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const redirectToHome = () => {
    window.location.href = '/';
  };
  if (!isAuthenticated()) {
    return null;
  }
  return (
    <Fragment>
      <StyledAppBar color="default" position="relative">
        <StyledToolbar>
          <Flex justifyContent="space-between" alignItems="center">
            <StyledIconButton
              onClick={
                isCollectionPage || isProductPage
                  ? history.goBack
                  : handleDrawerOpen
              }
              color="inherit"
              aria-label="Menu"
              data-testid="menu-btn-test"
            >
              {isCollectionPage || isProductPage ? <BackIcon /> : <MenuIcon />}
            </StyledIconButton>
            <MediaQuery maxWidth={480}>
              {matches => {
                if (isSearchOpen && !isCollectionPage && matches) {
                  return null;
                }
                return (
                  <Typography variant="h6" color="inherit">
                    {title}
                  </Typography>
                );
              }}
            </MediaQuery>
          </Flex>
          {isImportPage && (
            <div>
              <Button
                margin={5}
                disabled={activeStep === 0 || activeStep === 2}
                onClick={() => updateStep(activeStep - 1)}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!fileData || loading}
                onClick={() =>
                  activeStep !== 2
                    ? updateStep(activeStep + 1)
                    : redirectToHome()
                }
                component="span"
              >
                Next
              </Button>
            </div>
          )}
          <MediaQuery minWidth={768}>
            {matches => {
              if (isCollectionPage || isImportPage) {
                return null;
              }
              if (matches) {
                return <SearchBar />;
              }
              return <MobileSearchBar />;
            }}
          </MediaQuery>
          {!isCollectionPage && !isImportPage && !isSearchOpen && (
            <Button
              data-testid="collection-btn-test"
              variant="outlined"
              size="medium"
              onClick={() => history.push('/collection')}
            >
              COLLECTION ({collection && collection.length})
            </Button>
          )}
          <MediaQuery maxWidth={480}>
            {matches => {
              if (matches && isSearchOpen) {
                return (
                  <Fab size="small" onClick={() => history.push('/collection')}>
                    {collection.length}
                  </Fab>
                );
              }
              if (isSearchOpen) {
                return (
                  <Button
                    data-testid="collection-btn-test"
                    variant="outlined"
                    size="medium"
                    onClick={() => history.push('/collection')}
                  >
                    COLLECTION ({collection.length})
                  </Button>
                );
              }
              return null;
            }}
          </MediaQuery>
        </StyledToolbar>
      </StyledAppBar>
      <StyledDrawer
        data-testid="navbar-drawer-test"
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />

        <StyledList>
          <Profile flexDirection="column" pt={5} pb={3} pl={3}>
            <ProfileAvatar>
              {user && user.initials ? (
                user.initials
              ) : (
                <span role="img" aria-label="No data">
                  🙂
                </span>
              )}
            </ProfileAvatar>
            <User mt={2} ml={1}>
              {user && user.firstName} {user && user.lastName}
            </User>
          </Profile>

          <StyledLink
            activeClassName="selected"
            onClick={handleDrawerClose}
            exact
            component={NavLink}
            to="/"
            button
            key="product"
          >
            <StyledListItemIcon>
              <AppsIcon />
            </StyledListItemIcon>
            <Text>Products</Text>
          </StyledLink>
          {user && user.userRole === 'manager' && (
            <StyledLink
              activeClassName="selected"
              onClick={handleDrawerClose}
              component={NavLink}
              exact
              to="/import"
              button
              key="import"
            >
              <StyledListItemIcon>
                <CloudIcon />
              </StyledListItemIcon>
              <Text>Bulk Import</Text>
            </StyledLink>
          )}

          {isAuthenticated() && (
            <LogoutButton
              size="small"
              variant="outlined"
              onClick={() => logOut()}
            >
              Log out
            </LogoutButton>
          )}
        </StyledList>
        <Divider />
      </StyledDrawer>
    </Fragment>
  );
};

NavBar.propTypes = {
  title: string,
  match: shape({}).isRequired,
  history: shape({}).isRequired,
  user: shape({}),
};

NavBar.defaultProps = {
  title: '',
  user: {},
};

export default withRouter(withAuth(NavBar));
