import { gql } from 'apollo-boost';

export default gql`
  fragment filters on products {
    id
    crusher
    metso_id
    catalog_id
    description
    oem
    replace_quantity
    crusher_type
    brand
    group
  }

  query(
    $brand: String
    $crusher_type: String
    $crusher: String
    $group: String
    $includesbrand: Boolean! = true
    $includescrusher_type: Boolean! = false
    $includescrusher: Boolean! = false
    $includesgroup: Boolean! = false
  ) {
    productsAggregate: products_aggregate(
      where: {
        _and: {
          brand: { _eq: $brand }
          crusher_type: { _eq: $crusher_type }
          crusher: { _eq: $crusher }
          group: { _eq: $group }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    products(
      limit: 3000
      where: {
        _and: {
          brand: { _eq: $brand }
          crusher_type: { _eq: $crusher_type }
          crusher: { _eq: $crusher }
          group: { _eq: $group }
        }
      }
    ) {
      crusher
      metso_id
      catalog_id
      description
      oem
      replace_quantity
      crusher_type
      brand
      group
      ...filters
    }
    brand: products(distinct_on: [brand]) @include(if: $includesbrand) {
      id
      value: brand
    }
    crusher_type: products(
      distinct_on: [crusher_type]
      where: { _and: { brand: { _eq: $brand } } }
    ) @include(if: $includescrusher_type) {
      id
      value: crusher_type
    }
    crusher: products(
      distinct_on: [crusher]
      where: {
        _and: { brand: { _eq: $brand }, crusher_type: { _eq: $crusher_type } }
      }
    ) @include(if: $includescrusher) {
      id
      value: crusher
    }
    group: products(
      distinct_on: [group]
      where: {
        _and: {
          brand: { _eq: $brand }
          crusher_type: { _eq: $crusher_type }
          crusher: { _eq: $crusher }
        }
      }
    ) @include(if: $includesgroup) {
      id
      value: group
    }
  }
`;
