/* eslint-disable camelcase */
import React, { useState } from 'react';
import { shape } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components/macro';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';

import SEARCH_PRODUCTS from '../SearchBar/product-search-query';
import { allColors } from '../../constants/styles';
import { Dropdown } from '../SearchBar';

const SearchBarWrapper = styled.div`
  & fieldset {
    border: 0;
  }
`;

const StyledTextField = styled(TextField)`
  color: ${allColors.backgroundLightGrey};
  height: 38px;
  width: 100%;
  &:active {
    border-color: ${allColors.backgroundLightGrey};
  }
`;

const SearchIcon = styled(Search)`
  transform: rotate(90deg);
  color: ${allColors.backgroundDarkGrey};
`;

const CloseIcon = styled(Close)`
  color: ${allColors.backgroundDarkGrey};
`;

const MobileSearchBar = ({ history }) => {
  const isOpen = useStoreState(state => state.search.isSearchOpen);
  const toggleSearch = useStoreActions(actions => actions.search.toggleSearch);
  const [keyword, setKeyword] = useState('');
  const { data, error } = useQuery(SEARCH_PRODUCTS, {
    variables: { search: keyword },
    skip: keyword.length < 3,
    suspend: false,
  });

  const toggleOpen = () => {
    toggleSearch(true);
  };

  const toggleClose = () => {
    toggleSearch(false);
    setKeyword('');
  };

  const handleRowClick = rowId => {
    toggleClose();
    setKeyword('');
    history.push(`/product/${rowId}`);
  };

  if (error) {
    return <p>Error ${error.message}</p>;
  }
  return (
    <SearchBarWrapper>
      {!isOpen && (
        <IconButton
          onClick={() => toggleOpen()}
          data-testid="search-toggle-test"
        >
          <SearchIcon />
        </IconButton>
      )}
      {isOpen && (
        <StyledTextField
          placeholder="Search your item here"
          onChange={event => setKeyword(event.target.value)}
          value={keyword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" onClick={() => toggleClose()}>
                <CloseIcon />
              </InputAdornment>
            ),
          }}
          data-testid="mobile-searchbar-test"
        />
      )}
      {isOpen && data && (
        <Dropdown
          data={data}
          keyword={keyword}
          history={history}
          handleRowClick={handleRowClick}
        />
      )}
    </SearchBarWrapper>
  );
};

MobileSearchBar.propTypes = {
  history: shape({}).isRequired,
};

export default withRouter(MobileSearchBar);
