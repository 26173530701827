import React from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import bgImage from '../../assets/login-bg.svg';
import logoUrl from '../../assets/Metso-Contender-Series.png';

const urlPrefix =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5000' : '';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: bottom right;
  background-image: url(${bgImage});
`;

const Title = styled.h1`
  font-size: 2.75rem;
  font-weight: 200;
`;

const TitleSmall = styled.p`
  font-size: 1.875rem;
  font-weight: 600;
`;

const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const Logo = styled.img`
  align-self: flex-start;
  margin-top: 2rem;
  margin-left: 2rem;
  width: 350px;
`;

const Version = styled.p`
  width: 50px;
  margin: auto;
`;

const Disclaimer = styled.p`
  font-size: 0.75rem;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
`;

const FixedWrapper = styled.div`
  justify-content: flex-end;
  bottom: 10px;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LoginContainer = ({ match }) => {
  const query = useQuery();

  const unauthorizedMsg = `Seems like you don’t have permissions to access this application, please contact your Metso representative for access`;

  if (query.get('error') === 'unauthorized') {
    toast.error(unauthorizedMsg, { position: toast.POSITION.TOP_CENTER });
  }

  return (
    <Container>
      <Logo alt="Metso Logo" src={logoUrl} />
      <TitleWrapper>
        <TitleSmall>Welcome to</TitleSmall>
        <Title>Contender&trade; Series Catalog</Title>
        <Title style={{ marginBottom: '2rem' }}>for Crusher Parts</Title>
        <Button
          id="loginBtn"
          variant="contained"
          color="primary"
          size="large"
          href={`${urlPrefix}/auth/metso`}
        >
          Login with Metso Login
        </Button>
      </TitleWrapper>

      <FixedWrapper>
        <Disclaimer>
          ”All brand names, model names or marks are owned by their respective
          manufacturers. Metso has no affiliation with the original equipment
          manufacturer. These terms are used for identification purposes only
          and are not intended to indicate affiliation with or approval by the
          OEM. All parts are manufactured by, for and warranted by Metso and are
          not manufactured by, purchased from or warranted by the original
          equipment manufacturer.”
        </Disclaimer>
        <Version>{process.env.REACT_APP_VERSION}</Version>
      </FixedWrapper>
    </Container>
  );
};

export default LoginContainer;
