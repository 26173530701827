/* eslint-disable camelcase */
import React, { useState } from 'react';
import { shape, string, func } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import escapeRegExp from 'lodash.escaperegexp';
import styled from 'styled-components/macro';
import {
  TextField,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';

import SEARCH_PRODUCTS from './product-search-query';
import { allColors } from '../../constants/styles';

const SearchBarWrapper = styled.div`
  margin-left: auto;
  margin-right: 25px;
  & fieldset {
    border-width: 2px;
  }
`;

const StyledTextField = styled(TextField)`
  color: ${allColors.backgroundLightGrey};
  height: 38px;
  width: 309px;
  flex-direction: row;
  justify-content: flex-end;
  &:active {
    border-color: ${allColors.backgroundLightGrey};
  }
`;

const SearchIcon = styled(Search)`
  transform: rotate(90deg);
  color: ${allColors.backgroundDarkGrey};
`;

const StyledTableRow = styled(TableRow)`
  display: flex;
  flex-direction: row;
  height: 60px;
`;

const TableCell = styled(MuiTableCell)`
  width: 100px;
  flex-shrink: 0;
  flex: 1 0 0;
  display: flex;
  padding-left: 10px;
  padding-right: 10px !important;
  white-space: nowrap;
  align-items: center;
`;

const CellValue = styled.span`
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter(part => part)
        .map((part, i) =>
          // eslint-disable-next-line react/no-array-index-key
          regex.test(part) ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </span>
  );
};

Highlighted.propTypes = {
  text: string.isRequired,
  highlight: string.isRequired,
};

export const Dropdown = ({
  data: { search_products },
  keyword,
  handleRowClick,
}) => {
  if (search_products && search_products.length !== 0) {
    return (
      <Paper
        style={{
          position: 'absolute',
          top: '64px',
          left: 0,
          width: '100%',
          overflowX: 'scroll',
        }}
      >
        <Table data-testid="search-dropdown-test">
          <TableHead component="thead">
            <StyledTableRow component="tr">
              <TableCell component="td" variant="head">
                Crusher
              </TableCell>
              <TableCell component="td" variant="head">
                Metso id
              </TableCell>
              <TableCell component="td" variant="head">
                Catalog id
              </TableCell>
              <TableCell component="td" variant="head">
                OEM Reference
              </TableCell>
              <TableCell component="td" variant="head">
                Crusher Type
              </TableCell>
              <TableCell component="td" variant="head">
                Brand
              </TableCell>
              <TableCell component="td" variant="head">
                Description
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {search_products.map(row => (
              <StyledTableRow
                key={row.id}
                component="tr"
                onClick={() => handleRowClick(row.id)}
                hover
              >
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.crusher} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.metso_id} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.catalog_id} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.oem} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.crusher_type} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.brand} highlight={keyword} />
                  </CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>
                    <Highlighted text={row.description} highlight={keyword} />
                  </CellValue>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return (
    <Paper
      style={{
        position: 'absolute',
        top: '64px',
        left: 0,
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <Table>
        <TableHead>
          <StyledTableRow>
            <TableCell component="td" variant="head">
              Your search - {keyword} - did not match any products.
            </TableCell>
          </StyledTableRow>
        </TableHead>
      </Table>
    </Paper>
  );
};

Dropdown.propTypes = {
  data: shape({}),
  keyword: string,
  handleRowClick: func.isRequired,
};

Dropdown.defaultProps = {
  data: {},
  keyword: '',
};

const SearchBar = ({ history }) => {
  const [keyword, setKeyword] = useState('');
  const [toggle, setToggle] = useState(true);
  const { data, error } = useQuery(SEARCH_PRODUCTS, {
    variables: { search: keyword },
    skip: keyword.length < 3,
    suspend: false,
  });

  const handleRowClick = rowId => {
    setToggle(false);
    setKeyword('');
    history.push(`/product/${rowId}`);
  };

  const handleOnChange = event => {
    setToggle(true);
    setKeyword(event.target.value);
  };

  if (error) {
    return <p>Error ${error.message}</p>;
  }
  return (
    <SearchBarWrapper>
      <StyledTextField
        placeholder="Search your item here"
        onChange={event => handleOnChange(event)}
        value={keyword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        data-testid="searchbar-test"
      />
      {data && toggle && (
        <Dropdown
          data={data}
          keyword={keyword}
          history={history}
          handleRowClick={handleRowClick}
        />
      )}
    </SearchBarWrapper>
  );
};

SearchBar.propTypes = {
  history: shape({}).isRequired,
};

export default withRouter(SearchBar);
