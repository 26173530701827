import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'myriad-pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      light: '#FEECE8',
      main: '#E05314',
      dark: '#C73612',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E05314',
    },
  },
});

export default theme;
