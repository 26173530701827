import { withProps } from 'recompose';
import { getUser } from '../../utils/auth';

/**
 * HOC that injects an `auth` property into the wrapped component.
 * The auth property will contain information about the user's
 * authentication state from auth0. More specifically
 *
 * - this hoc will ALWAYS inject at least `{ auth: { isAuthenticated: [bool] } }`
 * - the auth object may contain: `token`, `user`, `error` properties
 *   depending on the state of the session
 *
 * @see utils/auth.js for implementation details
 */
export default Component =>
  withProps(() => ({
    ...getUser(),
  }))(Component);
