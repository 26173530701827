import React from 'react';
import { string } from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { Flex, Text } from 'rebass';

import { allColors } from '../../constants/styles';

const fadeOut = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const LoadingWrapper = styled.span`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;
const LoadingDot = styled.span`
  display: flex;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  font-size: 2rem;
  color: ${allColors.turquoiseGreen};
  animation: ${fadeOut} 1.4s linear infinite;
  animation-fill-mode: both;
  animation-delay: ${props => (props.delay ? props.delay : '0')};
`;

const Loading = ({ text }) => (
  <LoadingWrapper>
    <Flex alignItems="center">
      <LoadingDot>•</LoadingDot>
      <LoadingDot delay="0.2s">•</LoadingDot>
      <LoadingDot delay="0.4s">•</LoadingDot>
    </Flex>
    <Text>{text}</Text>
  </LoadingWrapper>
);

Loading.defaultProps = {
  text: '',
};

Loading.propTypes = {
  text: string,
};

export default Loading;
