import React from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'qs';
import styled from 'styled-components/macro';
import { ListItemText, List, ListItem, Paper } from '@material-ui/core';

export const filterKeys = ['brand', 'crusher_type', 'crusher', 'group'];

const StyledList = styled(List)`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0;
  &&.last-list a.last-selected {
    background: ${props => props.theme.palette.primary.main};
    span {
      color: white;
    }
  }
`;

const FilterListTitle = styled.h3`
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  color: #000;
  font-weight: lighter;
  color: gray;
  font-size: 12px;
  font-weight: 600;
  padding: 20px 16px;
  letter-spacing: 1px;
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

const FilterListScroller = styled.div`
  overflow-y: auto;
  border-right: 1px solid #f0f0f0;
  flex-grow: 1;
`;

const FilterListWrapper = styled.div`
  width: ${100 / 4}%;
  overflow: hidden;
  height: 250px;
  display: flex;
  flex-direction: column;
`;

const StyledListItem = styled(ListItem)`
  span {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledPaper = styled(Paper)`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  z-index: 10;
`;

export const buildLink = (where, key, obj) => {
  const currentLevel = filterKeys.indexOf(key);

  if (currentLevel === -1) {
    return '?';
  }
  // eslint-disable-next-line camelcase
  const { brand, crusher_type, crusher, group } = where;
  switch (filterKeys[currentLevel]) {
    case 'brand':
      return `?${qs.stringify(obj)}`;
    case 'crusher_type':
      return `?${qs.stringify({ brand, ...obj })}`;
    case 'crusher':
      return `?${qs.stringify({ brand, crusher_type, ...obj })}`;
    case 'group':
      return `?${qs.stringify({
        brand,
        crusher_type,
        crusher,
        group,
        ...obj,
      })}`;
    default:
      return '/';
  }
};

const renderFilters = (where, filters) =>
  Object.keys(filters).map((key, index) => {
    const currentLevel = filterKeys.indexOf(key);
    if (currentLevel === -1) {
      return null;
    }
    if (filterKeys.indexOf(key) === filterKeys[currentLevel - 1]) {
      return null;
    }
    if (!filters[key] || !filters[key][0] || !filters[key][0].value) {
      return null;
    }
    return (
      <FilterListWrapper key={key}>
        <FilterListTitle>{key.replace('_', ' ')}</FilterListTitle>
        <FilterListScroller>
          <StyledList
            component="nav"
            className={
              Object.keys(where).length - 1 === index ? 'last-list' : ''
            }
          >
            {filters[key]
              .filter(i => i.id !== null)
              .map(f =>
                f.value ? (
                  <StyledListItem
                    key={f.id}
                    button
                    component={Link}
                    to={buildLink(where, key, { [key]: f.value })}
                    selected={where[key] === f.value}
                    className={where[key] === f.value ? 'last-selected' : ''}
                  >
                    <ListItemText primary={f.value} />
                  </StyledListItem>
                ) : null,
              )}
          </StyledList>
        </FilterListScroller>
      </FilterListWrapper>
    );
  });

const Filters = ({ where, filters }) => (
  <StyledPaper>{renderFilters(where, filters)}</StyledPaper>
);

Filters.propTypes = {
  where: shape({}),
  filters: shape({}),
};

Filters.defaultProps = {
  where: {},
  filters: [],
};

export default Filters;
