import React from 'react';
import { shape, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated, getUser } from '../../utils/auth';

const PrivateRoute = ({
  // eslint-disable-next-line react/prop-types
  component: Component,
  scrollableDiv,
  superPrivate,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated()) {
        if (superPrivate && superPrivate !== getUser().user.userRole) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component scrollableDiv={scrollableDiv} {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

PrivateRoute.defaultProps = {
  scrollableDiv: {},
  location: {},
  superPrivate: '',
};

PrivateRoute.propTypes = {
  scrollableDiv: shape({}),
  location: shape({}),
  superPrivate: string,
};

export default PrivateRoute;
